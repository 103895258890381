
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.mainBannerWrapper {
    width: 100%;
    align-items: flex-start;

    padding-left: 30px;
    @media screen and (max-width: 1100px) {
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 767px) {
        width: 80%;
        padding-left: 0;
    }
}
.main-banner {
    // display: flex;
    // justify-content: center;
    position: relative;

    .form {
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 700px;
        // border-radius: 10px;
        min-height: 500px;
        background: #fff;
        box-shadow: 0px 4px 22px 2px rgba(0, 0, 0, 0.15);
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        position: relative;
        // box-shadow: 0px 13px 12px 5px rgba(0, 0, 0, 0.25);
        @media screen and (max-width: 1100px) {
            background: #fff;
        }
        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
            // @include lg {
            //     margin-left: -50px;
            //     margin-right: -50px;
            // }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background-color: $copiercosts-default-button-color !important;
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 450px !important;
            }
            &:hover {
                background-color: $copiercosts-default-button-hover-color !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 450px;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        border: 2px solid
                            $copiercosts-default-radio-selected-borderColor;
                        background-color: $copiercosts-default-radio-selected-borderColor;
                        color: #fff;
                        img {
                            filter: brightness(1000%);
                        }
                    }
                }
                label {
                    border-radius: 3px;
                    border: 2px solid $copiercosts-default-button-color;
                    // background-color: $businessphoneprices-go-go-green;
                    color: $copiercosts-default-radio-text-color;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    flex-direction: row;
                    &:hover {
                        border: 2px solid
                            $copiercosts-default-radio-selected-borderColor;
                        background-color: $copiercosts-default-radio-selected-borderColor;
                        color: #fff;
                        img {
                            filter: brightness(1000%);
                        }
                    }
                    span {
                        font-size: 16px !important;
                    }
                    & > div {
                        margin-right: 15px;
                        & + span {
                            margin-top: 0;
                            margin-right: inherit;
                            width: auto;
                        }
                    }
                    & > span {
                        margin-right: auto;
                        width: 100%;
                    }

                    @include md {
                        min-height: 50px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include lg {
            min-width: 600px;
            // width: 60%;
            padding: 40px 40px;
            margin-bottom: 200px;
            margin-top: 50px;
        }
    }

    .image {
        z-index: 2;
        position: absolute !important;
        left: 0;
        bottom: 0;
        height: 300px;
        width: 100vw;
        max-width: unset !important;
        left: 50% !important;
        transform: translateX(-50%) !important;

        @media screen and (max-width: 1023px) {
            img {
                min-height: unset !important;
                min-width: unset !important;
                width: auto !important;
                height: 300px !important;
                margin-right: 0 !important;
                max-width: unset !important;
            }
        }

        @include lg {
            height: 100%;
            top: 0 !important;
            left: 0%;
        }
    }
}
.progress-background {
    & > div:first-child span {
        color: $copiercosts-default-progress-buble-text-color;
    }
    & > div:last-child {
        background-color: $copiercosts-default-progress-bgcolor;
    }
}
