$businessphoneprices-go-go-green: #008a7b;
$businessphoneprices-green: #5bc1a4;

$copiercosts-footer-text-color: #000;
$copiercosts-footer-separator-color: #006336;
$copiercosts-offer-call-number-color: #006336;
$copiercosts-why-us-brief-color: #747474;
$copiercosts-default-button-color: #0c4d93;
$copiercosts-default-button-hover-color: #13467c;
$copiercosts-default-radio-selected-borderColor: #eb4780;
$copiercosts-default-radio-text-color: #0c4d93;
$copiercosts-default-progress-color: #0c4d93;
$copiercosts-default-progress-bgcolor: #f0f0f0;
$copiercosts-default-progress-buble-text-color: #fff;

:export {
    green: $businessphoneprices-green;
    whyUsBriefColor: $copiercosts-why-us-brief-color;
    offerCallNumber: $copiercosts-offer-call-number-color;
    defaultButtonColor: $copiercosts-default-button-color;
    defaultProgressColor: $copiercosts-default-progress-color;
}
