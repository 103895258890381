
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.topSection {
    height: auto;
    min-height: 100vh;
    background: rgb(205, 232, 255);
    background: linear-gradient(
        0deg,
        rgba(205, 232, 255, 1) 0%,
        rgba(205, 232, 255, 1) 50%,
        rgba(233, 245, 255, 1) 50%,
        rgba(233, 245, 255, 1) 100%
    );
}
.mxWrapper {
    width: 100%;
    max-width: 1380px;
    margin: 0 auto;
}
